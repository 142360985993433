import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { parseSections } from "../utils"

const pageTemplate = ({ data, pageContext }) => {
  const { slug } = pageContext
  const { allMarkdownRemark } = data
  const theme = {}

  if (!(allMarkdownRemark && allMarkdownRemark.edges)) {
    return null
  }

  const { node } = allMarkdownRemark.edges[0]
  const { frontmatter, fields = {} } = node
  const { title, navTheme = "light" } = frontmatter

  if (fields.slug === "/" || navTheme === "dark")
    theme.nav = {
      mode: "dark",
    }

  return (
    <Layout theme={theme} slug={slug}>
      <SEO title={title} />
      {parseSections(data)}
    </Layout>
  )
}

export const query = graphql`
  query Page($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      ...SectionFragment
    }
  }
`

export default pageTemplate
